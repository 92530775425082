<template>
  <div class="full-page">
    <template v-if="doctorInfo">
      <div class="header">
        <img src="@/assets/head-bg2.png">
      </div>
      <div class="doctors">
        <div class="doctor">
          <div class="card-left">
            <img v-if="doctorInfo.headUrl" :src="doctorInfo.headUrl | formatPicture">
            <img v-else src="@/assets/default-portrait.png">
          </div>
          <div class="card-right">
            <div class="title"><span>{{doctorInfo.name}}</span></div>
            <div class="doctor-tag"><span>{{doctorInfo.positionName}}</span></div>
            <div class="registration-fee"><span>¥{{doctorInfo.price | formatPrice}}</span></div>
          </div>
        </div>
      </div>

      <div class="doctors-specialty-column">
        <div class="doctors-specialty">
          <div class="title"><span>擅长</span></div>
          <div class="specialty">{{doctorInfo.brief}}</div>
        </div>
      </div>

       <div class="registration">
          <van-button color="#00B6A4" block round style="height: .8rem" @click.stop="toBooking">去预约</van-button>
       </div>
    </template>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        doctorInfo: '',
        bookingId: ''
      }
    },

    created() {
      this.getDoctorDetail()
    },

    methods: {
      getDoctorDetail () {
        this.$request ({
          url: '/doctors/detail/' + this.$route.params.id,
          data: {}
        }).then( res => {
          this.doctorInfo = res.result
        })
      },

      toBooking () {
        this.$router.push({path: '/date-selection', name: 'date-selection', params: {id: this.$route.params.id}})
      }
    },
  }
</script>

<style lang="scss" scoped>
  .full-page {
    .header {
      img {
        width: 100%;
      }
    }

    .doctors {
      padding: 0 .24rem;
      margin-top: -1.34rem;
      position: relative;
      .doctor {
        background: #fff;
        border-radius: .08rem;
        display: flex;
        align-items: center;
        padding: .24rem;
        margin-top: .24rem;
        &:nth-child(1) {
          margin-top: 0;
        }
        .card-left {
          margin-right: .24rem;
          border-radius: .08rem;
          overflow: hidden;
          img {
            width: 1.6rem;
            height: 1.6rem;
            object-fit: cover;
            display: block;
          }
        }
        .card-right {
          position: relative;
          flex: 1;
          .title {
            font-size: .32rem;
            margin-bottom: .08rem;
          }
          .doctor-tag {
            width: max-content;
            padding: .04rem;
            border-radius: .06rem;
            color: #00B6A4;
            border: 1px solid #00B6A4;
            font-size: .22rem;
          }
          .registration-fee {
            font-size: .36rem;
            color: #E02021;
            margin-top: 0.27rem;
          }
        }
      }
    }

    .doctors-specialty-column {
      padding: 0 .24rem;
      margin-top: .24rem;
      .doctors-specialty {
        background: #fff;
        border-radius: .16rem;
        .title {
          padding: .24rem;
          border-bottom: 1px solid #EEEEEE;
          font-size: .3rem;
          font-weight: bold;
        }
        .specialty {
          padding: .24rem;
          font-size: .26rem;
          line-height: .4rem;
        }
      }
    }

    .registration {
      margin-top: .8rem;
      text-align: center;
      padding: 0 2rem;
    }
  }
  
</style>